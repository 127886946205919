<template>
  <div class="site-overview-compare">
    <div class="compare_label">对比:</div>
    <el-checkbox-group v-model="localCompareType" @change="change">
      <el-checkbox size="mini" label="lastday" style="margin-right: 10px">
        前一日
      </el-checkbox>
      <el-checkbox size="mini" label="lastweek">上周同期</el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "CompareComponent",
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      localCompareType: [],
    };
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          this.localCompareType = [newVal];
        } else {
          this.localCompareType = [];
        }
      },
      immediate: true,
    },
  },
  methods: {
    change(newVal) {
      this.localCompareType = [newVal[newVal.length - 1]];
      this.$emit("input", newVal[newVal.length - 1]);
    },
  },
};
</script>

<style>
.site-overview-compare {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.site-overview-compare .tabs .el-tabs__content {
  padding: 0;
  overflow: visible;
}
.site-overview-compare .el-checkbox__label {
  font-size: 12px;
  color: #323437;
}
</style>
