<template>
  <div class="site-overview-detail container">
    <SearchForm
      :isTrend="config.isTrend"
      :title="config.title"
      :search="search"
      :daterange="daterange"
      @search="handleSearch"
    />
    <div class="main">
      <el-tabs
        v-if="!config.isTrend"
        type="border-card"
        v-model="search.chart_category"
        class="tabs"
        @tab-click="changeTab"
      >
        <el-tab-pane
          v-for="tab in config.chartCategories"
          :name="tab.value"
          :label="tab.label"
          :key="tab.value"
        >
          <div v-if="tab.value === search.chart_category">
            <div class="chart-control">
              <el-radio-group
                v-model="search.metric"
                size="small"
                @change="() => emitSort(search.metric, 'descending')"
              >
                <el-radio-button
                  v-for="item in metricList"
                  :label="item.value"
                  :key="item.value"
                >
                  {{ item.label }}
                </el-radio-button>
              </el-radio-group>
            </div>
            <div class="chart-wrapper">
              <LinePieChart
                :loading="requestHandler.loading"
                :dataIndex="tab.dataIndex"
                :listProperty="tab.listProperty"
                :daterange="daterange"
                :metric="search.metric"
                :trendData="trendData"
                :pieData="pieData"
                :summary="summary"
                :isFoldedChart="isFolded"
              />
            </div>
            <div class="fold-text" v-show="isFolded">趋势图</div>
            <div class="chart-fold" :class="{ active: isFolded }">
              <span class="chart-fold_icon-wrapper" @click="toggleFold">
                <i class="el-icon-d-arrow-right chart-footer_icon"></i>
              </span>
            </div>
            <div class="table-warpper">
              <DetailTable
                ref="detailTable"
                :loading="requestHandler.loading"
                :name="tab.label"
                :dataIndex="tab.dataIndex"
                :isLink="tab.isLink"
                :tableData="tableData"
                :pageSizes="pageSizes"
                :page="page"
                :totalCount="totalCount"
                :metric="search.metric"
                :isTrend="config.isTrend"
                @sort="handleSort"
                @change="getData"
              />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-card v-else :body-style="{ padding: '0px' }">
        <div class="chart-control">
          <el-radio-group v-model="search.metric" size="small">
            <el-radio-button
              v-for="item in metricList"
              :label="item.value"
              :key="item.value"
            >
              {{ item.label }}
            </el-radio-button>
          </el-radio-group>
        </div>
        <div style="padding-bottom: 20px">
          <IndexSummary :data="summary" />
          <div
            v-show="!isFolded"
            style="border-top: 1px solid #f0f0f0; padding-top: 20px"
          >
            <TrendChart
              :data="trendData"
              :compareData="compareData"
              :metric="search.metric"
              :daterange="daterange"
              :compareDateRange="compareDateRange"
              :timeUnit="timeUnit"
              :loading="requestHandler.loading"
              :isFolded="isFolded"
            />
            <div style="padding-top: 10px">
              <Compare
                v-model="compareType"
                v-if="isOneDay && !isEmpty(trendData)"
              />
            </div>
          </div>
        </div>
        <div class="fold-text" v-show="isFolded">趋势图</div>
        <div class="chart-fold" :class="{ active: isFolded }">
          <span class="chart-fold_icon-wrapper" @click="toggleFold">
            <i class="el-icon-d-arrow-right chart-footer_icon"></i>
          </span>
        </div>
        <div class="table-warpper">
          <DetailTable
            name="日期"
            :dataIndex="isHour ? 'hour' : 'date'"
            :tableData="tableData"
            :pagination="false"
            :loading="requestHandler.loading"
            :metric="search.metric"
            :isTrend="config.isTrend"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { metricList } from "../data";
import {
  basicIndex,
  calcAVD,
  calcBR,
  calcPPU,
  fillMissingDates,
  fillMissingHours,
  getCompareDateRange,
  getDateRange,
} from "../util";
import LinePieChart from "../components/LinePieChart.vue";
import DetailTable from "../components/DetailTable.vue";
import RequestHandler from "@/utils/request";
import debounce from "lodash/debounce";
import SearchForm from "../components/SearchForm.vue";
import IndexSummary from "./Summary.vue";
import TrendChart from "./TrendChart.vue";
import { isEmpty } from "@/utils";
import Compare from "../components/Compare.vue";

export default {
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isFolded: false,
      isEmpty,

      timeUnit: "hour",
      compareDateRange: null,
      metricList,
      daterange: getDateRange("yesterday"),
      compareType: undefined,

      search: {
        chart_type: undefined,
        daterange: getDateRange("yesterday"),
        device_type: 0,
        chart_category: undefined,
        metric: "pv",
        country: undefined,
        path: undefined,
        remote_addr: undefined,
        referer_type: 0,
        status_code: undefined,
        time_unit: undefined,
        sort_order: "",
      },

      trendData: [],
      pieData: [],
      summary: {},
      tableData: [],

      requestHandler: new RequestHandler(),
      totalCount: 0,
      pageSizes: [20, 50, 100, 150],
      page: {
        page_number: 1,
        page_size: 20,
      },

      requestCompareData: null,
      debounceGetCompareData: null,
      compareData: [],

      debounceGetData: null,
    };
  },
  computed: {
    isHour() {
      return this.timeUnit === "hour" || this.isOneDay;
    },
    isOneDay() {
      return this.daterange[0] === this.daterange[1];
    },
  },
  watch: {
    "config.defaultSearchParams": {
      handler(v) {
        this.search = { ...this.search, ...v };
      },
      deep: true,
      immediate: true,
    },
    compareType: {
      handler(v) {
        if (v != null) {
          this.debounceGetCompareData();
        } else {
          this.compareData = [];
          this.compareDateRange = null;
        }
      },
      deep: true,
    },
  },
  created() {
    const fetchData = async (params) => {
      return await this.$common.httpPost(
        "/api/log/admin/nginx/find/visualization",
        params
      );
    };
    this.requestData = this.requestHandler.create(fetchData);
    this.requestCompareData = this.requestHandler.create(fetchData);

    this.debounceGetData = debounce(this.getData, 300);
    this.debounceGetCompareData = debounce(this.getCompareData, 300);
  },
  mounted() {
    if (this.config.isTrend) {
      // 因为趋势详情detailTable组件的sortable不等于custom，所以不会触发sort事件，所以要手动初始化数据
      this.getData();
    } else {
      // 其它类型触发sort事件初始化数据
      this.emitSort(
        this.search.metric,
        this.search.sort_order === "-" ? "ascending" : "descending"
      );
    }
  },
  methods: {
    changeTab() {
      if (this.config.isTrend) return;
      this.emitSort(this.search.metric, "descending");
    },
    emitSort(prop, order) {
      this.$nextTick(() => {
        this.$refs.detailTable[0].sort(prop, order);
      });
    },
    handleSort({ prop, order }) {
      this.search.metric = prop;
      this.search.sort_order = order;
      this.handleSearch();
    },
    toggleFold() {
      this.isFolded = !this.isFolded;
    },
    formatParams() {
      const { daterange, metric, sort_order, time_unit, ...resetValue } =
        this.search;
      const [begin_date, end_date] = daterange;

      const isTrend = this.config.isTrend === true;
      const trendCategoryType = isTrend
        ? {
            // 假如 chartCategory 传值了。 chartCategory = 11 按小时，否则按天。
            // chartCategory 没传值（0），那么 超过天按天，否则按小时
            // 仅趋势详情生效
            chart_category: time_unit === "hour" ? "11" : undefined,
          }
        : {};

      return {
        ...resetValue,
        begin_date,
        end_date,
        top_type: this.config.isTrend ? undefined : `${sort_order}${metric}`,
        ...trendCategoryType,
      };
    },
    handleSearch() {
      this.page.page_number = 1;

      // 异步切换避免参数不匹配
      this.trendData = [];
      this.pieData = [];
      this.summary = {};

      this.debounceGetData();
    },
    async getData() {
      try {
        const params = {
          ...this.formatParams(),
          page_number: this.page.page_number,
          page_size: this.config.isTrend ? -1 : this.page.page_size,
        };

        await this.requestData(params, (data) => {
          // 趋势分析
          if (this.config.isTrend) {
            const trend = data.chart.trend || [];
            this.trendData = [...trend];

            const basicIndexSum = basicIndex.reduce((acc, key) => {
              acc[key] = trend.reduce((sum, item) => sum + item[key], 0);
              return acc;
            }, {});

            this.summary = {
              ...basicIndexSum,
              br: calcBR(basicIndexSum),
              ppu: calcPPU(basicIndexSum),
              avd: calcAVD(basicIndexSum),
            };

            const isOneDay =
              this.search.daterange[0] === this.search.daterange[1];

            const isHour = this.search.time_unit === "hour" || isOneDay;

            const resetTrend = isHour
              ? fillMissingHours(trend)
              : fillMissingDates(trend, this.search.daterange);

            this.tableData = resetTrend.map((item) => {
              if (isHour) {
                return {
                  ...item,
                  hour: `${item.hour}:00 - ${item.hour}:59`,
                };
              } else {
                return {
                  ...item,
                  date: item.date,
                };
              }
            });
            // 其它
          } else {
            const chartCategory = this.config.chartCategories.find(
              (item) => item.value === this.search.chart_category
            );
            const listProperty = chartCategory.listProperty;
            const dataIndex = chartCategory.dataIndex;
            if (params.chart_category === "45") {
              const tableData = data.list[listProperty] || [];
              this.tableData = tableData.map((item) => {
                const label = [item[dataIndex], item.country, item.province]
                  .filter((item) => item && item !== "0")
                  .join(" - ");
                return {
                  ...item,
                  [dataIndex]: label,
                };
              });
            } else {
              this.tableData = data.list[listProperty] || [];
            }
            this.totalCount = data.result.list_total;

            this.summary = {
              ...data.result.basic_index,
              ...data.result.quality_index,
            };

            if (data.result.page_number === 1) {
              this.pieData = (data.list[listProperty] || []).slice(0, 10);
              this.trendData = (data.chart[listProperty] || []).filter(
                (item) =>
                  this.pieData.findIndex(
                    (v) => v[dataIndex] === item[dataIndex]
                  ) > -1
              );
            }
          }
        });
      } catch (error) {
        console.error(error);
        this.$message.error(error.message);
        this.trendData = [];
        this.pieData = [];
        this.summary = {};
        this.tableData = [];
        this.totalCount = 0;
      }
      this.daterange = this.search.daterange;
      this.timeUnit = this.search.time_unit;
    },
    async getCompareData() {
      try {
        const compareDateRange = getCompareDateRange(
          this.compareType,
          this.search.daterange[0]
        );

        const params = {
          ...this.formatParams(),
          begin_date: compareDateRange[0],
          end_date: compareDateRange[1],
          page_number: this.page.page_number,
          page_size: this.config.isTrend ? -1 : this.page.page_size,
        };

        await this.requestCompareData(params, (data) => {
          const { chartCategories } = this.config;
          const category =
            chartCategories.length === 1 && !this.search.chart_category
              ? chartCategories[0]
              : chartCategories.find(
                  (item) => item.value === this.search.chart_category
                );

          const listProperty = category.listProperty;

          this.compareData = data.chart[listProperty] || [];
          this.compareDateRange = compareDateRange;
        });
      } catch (error) {
        console.error(error);
        this.compareData = [];
        this.compareDateRange = null;
        this.$message.error(error.message);
      }
    },
  },
  beforeDestroy() {
    this.requestHandler.clearTimeout();
    this.debounceGetData.cancel();
  },
  components: {
    SearchForm,
    LinePieChart,
    DetailTable,
    IndexSummary,
    TrendChart,
    Compare,
  },
};
</script>

<style scoped>
.container {
  background: #ececec;
  min-width: 1120px;
  min-height: 100%;
}
.main {
  padding: 20px;
}
.chart-control {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  padding-bottom: 0;
}
.chart-fold {
  display: block;
  border-top: 1px solid #f0f0f0;
  box-sizing: content-box;
  margin: 0 auto;
}
.chart-fold_icon-wrapper {
  width: 50px;
  height: 26px;
  background: #fbfcfc;
  border: solid 1px #f0f0f0;
  border-top: none;
  margin: 0 auto;
  margin-top: -1px;
  display: block;
  cursor: pointer;
  box-sizing: content-box;
}
.chart-footer_icon {
  transform: rotate(-90deg);
  transition: all 0.3s ease;
}
.chart-fold.active .chart-footer_icon {
  transform: rotate(90deg);
}
.fold-text {
  text-align: left;
  padding: 10px 20px;
  font-size: 12px;
  border-top: 1px solid #f0f0f0;
}
</style>
<style>
.site-overview-detail .tabs .el-tabs__content {
  padding: 0;
  overflow: visible;
}
</style>