<template>
  <div class="container">
    <Detail :config="config" />
  </div>
</template>

<script>
import Detail from "../components/Detail.vue";
import { getDateRange } from "../util";

export default {
  data() {
    return {
      config: {
        title: "趋势分析",
        isTrend: true,

        defaultSearchParams: {
          chart_type: 1,
          device_type: 0,
          daterange: getDateRange("yesterday"),
          metric: "pv",
          time_unit: "hour",
        },
        chartCategories: [
          {
            listProperty: "trend",
            dataIndex: "source_type",
          },
        ],
      },
    };
  },
  components: {
    Detail,
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  overflow: auto;
}
</style>