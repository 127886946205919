<template>
  <div class="card table-card">
    <div class="card-title">
      <span>Top10{{ name }}</span>
      <span class="detail-report-btn" @click="goDetail">
        <i class="el-icon-arrow-right"></i>
      </span>
    </div>
    <div
      class="table-wrapper"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中"
    >
      <el-empty
        style="margin-top: 42px"
        v-if="isEmpty(tableData)"
        description="暂无数据"
      ></el-empty>
      <table v-else cellpadding="0" cellspacing="0" class="table">
        <thead>
          <tr>
            <td class="al">{{ name }}</td>
            <td class="ar" width="20%">浏览量(PV)</td>
            <td class="ratio" width="20%">占比</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index">
            <td class="al">
              <a
                v-if="isLink"
                target="_blank"
                class="ellipsis"
                :href="item[dataIndex]"
                >{{ item[dataIndex] }}</a
              >
              <span v-else class="ellipsis">{{ item[dataIndex] }}</span>
            </td>
            <td class="ar pv" width="20%">
              {{ formatValue(item.pv, "pv") }}
            </td>
            <td class="ratio" width="20%">
              <div
                :style="{
                  backgroundColor: '#dcebfe',
                  width: !isNaN(Number(item.percent)) ? item.percent + '%' : 0,
                }"
              >
                {{ item.percent + "%" }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { formatValue } from "../../util";
import { isNumber, isEmpty } from "@/utils";

export default {
  props: {
    name: {
      require: true,
      type: String,
    },
    dataIndex: {
      require: true,
      type: String,
    },
    chartType: {
      require: true,
      type: Number,
    },
    chartCategory: {
      require: true,
      type: Number,
    },
    daterange: {
      type: Array,
      default: () => [],
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    listProperty: {
      type: String,
    },
    detailPath: {
      type: String,
    },
    data: {
      type: Object,
      default: () => ({
        list: {
          source: [],
          page: [],
          visit: [],
        },
        result: {
          basic_index: {},
        },
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNumber,
      isEmpty,
      formatValue,
      tableData: [],
    };
  },
  watch: {
    data: {
      handler: function () {
        const sum = this.data.result.basic_index.pv;
        this.tableData = (
          this.data.list[this.listProperty || this.dataIndex] || []
        ).map((item) => {
          const percent = this.getPercent(item.pv, sum);
          return {
            ...item,
            percent,
          };
        });
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    goDetail() {
      this.$router.push(this.detailPath);
    },
    getPercent(count, sum) {
      count = parseFloat(count);
      sum = parseFloat(sum);

      if (!isNumber(count) || !isNumber(sum) || sum === 0) {
        return "--";
      }

      return ((count / sum) * 100).toFixed(2);
    },
  },
};
</script>

<style scoped>
.table-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.table-wrapper {
  padding: 0 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex: 1;
  box-sizing: border-box;
}

.table {
  width: 100%;
  table-layout: fixed;
  font-size: 13px;
}

.table thead {
  position: sticky;
  top: 0;
  background: #fff;
}

.table thead tr td {
  color: #787a7d;
  padding-bottom: 10px;
  line-height: 20px;
}

.table tbody tr td {
  color: #323437;
  /* overflow: hidden; */
  padding-bottom: 10px;
  line-height: 18px;
}

.ellipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.pv {
  word-break: break-all;
  word-wrap: break-word;
}

.ratio {
  text-align: left;
  padding-left: 20px;
  width: 90px;
}

.al {
  text-align: left;
}

.ar {
  text-align: right;
}

a {
  text-decoration: none;
  color: #1276e5;
  cursor: pointer;
}

a:hover {
  color: #282c33;
}
</style>