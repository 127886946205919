<template>
  <div class="legend-container">
    <div
      v-for="(item, index) in columns"
      :key="item.value || item"
      @click="handleLegendChange(item.value || item)"
    >
      <div
        slot="reference"
        class="legend"
        :style="{
          color: excludedValues.includes(item.value || item)
            ? '#999'
            : 'rgb(50, 52, 55)',
          cursor: readOnly ? 'default' : 'pointer',
        }"
      >
        <span
          class="legend_dot"
          :style="{
            backgroundColor: excludedValues.includes(item.value || item)
              ? '#999'
              : colors[index],
          }"
        ></span>
        {{ item.label || item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LegendComponent",
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    excludedValues: {
      type: Array,
      default: () => [],
    },
    colors: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleLegendChange(items) {
      if (this.readOnly) {
        return;
      }
      // 确保传入的 items 是一个数组，如果不是，转换为数组
      const itemsToExclude = Array.isArray(items) ? items : [items];

      // 对每个 item 进行处理，移除已存在的项，添加不存在的项
      const newExcludedValues = this.excludedValues
        .filter((value) => !itemsToExclude.includes(value))
        .concat(
          itemsToExclude.filter((item) => !this.excludedValues.includes(item))
        );

      this.$emit("legend-change", { items, excludedValues: newExcludedValues });
    },
  },
};
</script>

<style scoped>
.legend-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.legend {
  font-size: 12px;
  cursor: pointer;
  color: rgb(90, 92, 94);
  /* word-wrap: break-word; */
  word-break: break-all;
  padding: 20px;
  padding-bottom: 10px;
  padding-top: 0;
  max-width: 100%;
}
.legend:hover {
  color: rgb(50, 52, 55);
}
.legend_dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}
</style>