<template>
  <div class="container">
    <Detail :config="config" />
  </div>
</template>

<script>
import Detail from "../components/Detail.vue";
import { getDateRange } from "../util";

export default {
  data() {
    return {
      config: {
        title: "访客分析",
        defaultSearchParams: {
          chart_type: 4,
          device_type: 0,
          daterange: getDateRange("yesterday"),
          chart_category: this.$route.query.chart_category || "43",
          metric: "pv",
        },
        chartCategories: [
          {
            label: "国家",
            value: "43",
            listProperty: "visit",
            dataIndex: "country",
          },
          {
            label: "州/省",
            value: "44",
            listProperty: "visit",
            dataIndex: "province",
          },
          {
            label: "浏览器",
            value: "41",
            listProperty: "visit",
            dataIndex: "browser",
          },
          {
            label: "系统",
            value: "42",
            listProperty: "visit",
            dataIndex: "os",
          },
          {
            label: "IP",
            value: "45",
            listProperty: "visit",
            dataIndex: "remote_addr",
          },
        ],
      },
    };
  },
  components: {
    Detail,
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  overflow: auto;
}
</style>