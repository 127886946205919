import numeral from "numeral";
import { formatDate, isEmpty } from "@/utils";

export const getDateRange = (type) => {
  // 接口限制，最近的数据只能是前一天
  let endDate = new Date().setHours(0) - 3600 * 1000 * 24;
  // let endDate = new Date(2023, 9, 1).getTime() - 3600 * 1000 * 24;
  let startDate;
  if (type === "yesterday") {
    startDate = endDate;
  } else if (type === "beforeYesterday") {
    startDate = endDate - 3600 * 1000 * 24;
    endDate = startDate;
  } else if (type === "last7day") {
    startDate = endDate - 3600 * 1000 * 24 * 7;
  } else if (type === "last30day") {
    startDate = endDate - 3600 * 1000 * 24 * 30;
  }
  return [
    formatDate(startDate, "YYYY-MM-DD"),
    formatDate(endDate, "YYYY-MM-DD"),
  ];
};

export const basicIndex = ["pv", "uv", "vv", "ip", "spvv"];

export const qualityIndex = ["br", "ppu", "avd"];

export const getInexFormatStr = (metric) => {
  if (metric === "br") {
    return "0.00%"; // 百分比格式
  } else if (qualityIndex.includes(metric)) {
    return "0,0.00"; // 千分位并保留两位小数
  } else {
    return "0,0"; // 仅显示千分位
  }
};

export const getAxisYlabelFormatter = (metric) => {
  if (metric === "br") {
    return ".0%";
  } else {
    return "~s";
  }
};

export const formatValue = (value, metric) => {
  const str = getInexFormatStr(metric);
  if (!isEmpty(value)) {
    return numeral(value).format(str);
  } else {
    return "--";
  }
};

export function generateDateRange(date) {
  const start = new Date(date[0]);
  const end = new Date(date[1]);
  const dateArray = [];

  while (start <= end) {
    dateArray.push(formatDate(start, "YYYY-MM-DD"));
    start.setDate(start.getDate() + 1);
  }

  return dateArray;
}

// 跳出率
export const calcBR = (value) => {
  if (!value.spvv || !value.vv) return null;
  const { spvv, vv } = value;
  return spvv / vv;
};
// 人均浏览页数
export const calcPPU = (value) => {
  if (!value.pv || !value.uv) return null;
  const { pv, uv } = value;
  return pv / uv;
};
// 平均访问深度
export const calcAVD = (value) => {
  if (!value.pv || !value.vv) return null;
  const { pv, vv } = value;
  return pv / vv;
};

export function fillMissingDates(data, daterange, dataIndex = null) {
  const [startDate, endDate] = daterange || [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  const dates = [];

  // 生成日期数组
  for (
    let date = new Date(start);
    date <= end;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(date.toISOString().split("T")[0]);
  }

  const defaultEntry = {
    ...basicIndex.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}),
    ...qualityIndex.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}),
  };

  const filledData = [];

  // 如果 dataIndex 存在，按照其分组
  const categorys = dataIndex
    ? new Set(data.map((item) => item[dataIndex]))
    : [null];

  categorys.forEach((category) => {
    dates.forEach((date) => {
      const existingEntry = data.find(
        (item) =>
          item.date === date &&
          (dataIndex ? item[dataIndex] === category : true)
      );
      if (existingEntry) {
        filledData.push(existingEntry);
      } else {
        filledData.push({
          date,
          ...(dataIndex ? { [dataIndex]: category } : {}),
          ...defaultEntry,
        });
      }
    });
  });

  return filledData;
}

export function fillMissingHours(data, dataIndex = null) {
  const categorys = dataIndex
    ? new Set(data.map((item) => item[dataIndex]))
    : [null];

  const hours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0")
  );

  const defaultEntry = {
    ...basicIndex.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}),
    ...qualityIndex.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}),
  };

  const filledData = [];

  categorys.forEach((category) => {
    hours.forEach((hour) => {
      const existingEntry = data.find(
        (item) =>
          item.hour === hour &&
          (dataIndex ? item[dataIndex] === category : true)
      );
      if (existingEntry) {
        filledData.push(existingEntry);
      } else {
        filledData.push({
          hour,
          ...(dataIndex ? { [dataIndex]: category } : {}),
          ...defaultEntry,
        });
      }
    });
  });

  return filledData;
}

export function getCompareDateRange(compareType, date) {
  const ONE_DAY = 3600 * 1000 * 24;
  const ONE_WEEK = ONE_DAY * 7;
  const timestamp = new Date(date).getTime();
  const newTimestamp =
    compareType === "lastday" ? timestamp - ONE_DAY : timestamp - ONE_WEEK;
  const formattedDate = formatDate(newTimestamp, "YYYY-MM-DD");
  return [formattedDate, formattedDate];
}
