<template>
  <div class="container" id="siteOverview">
    <div class="header">
      <el-radio-group v-model="activeRecentTime" size="small">
        <el-radio-button
          v-for="item in recentTimeRanges"
          :label="item.value"
          :key="item.value"
          >{{ item.label }}</el-radio-button
        >
      </el-radio-group>
    </div>
    <div class="main">
      <div class="grid">
        <div class="item">
          <Trend
            :daterange="daterange"
            :data="data"
            :loading="requestHandler.loading"
          />
        </div>
        <div class="item" v-for="item in top10Data" :key="item.dataIndex">
          <TopTable
            :name="item.name"
            :dataIndex="item.dataIndex"
            :chartType="item.chartType"
            :chartCategory="item.chartCategory"
            :isLink="item.isLink"
            :daterange="daterange"
            :listProperty="item.listProperty"
            :detailPath="item.detailPath"
            :data="data"
            :loading="requestHandler.loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Trend from "./Trend/index.vue";
import TopTable from "./TopTable/index.vue";
import { recentTimeRanges, top10Data } from "../data";
import { getDateRange } from "../util";
import RequestHandler from "@/utils/request";
import { isEmpty } from "@/utils";
import { defaultData } from "./data";

export default {
  data() {
    return {
      top10Data,
      activeRecentTime: "yesterday",
      recentTimeRanges: recentTimeRanges,
      requestHandler: new RequestHandler(),
      requestData: null,
      data: JSON.parse(JSON.stringify(defaultData)),
    };
  },
  computed: {
    daterange() {
      return getDateRange(this.activeRecentTime);
    },
  },
  watch: {
    daterange: {
      handler: function () {
        this.getData();
      },
      deep: true,
    },
  },
  created() {
    this.requestData = this.requestHandler.create(async (params) => {
      return await this.$common.httpPost(
        "/api/log/admin/nginx/find/visualization",
        params
      );
    });
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      try {
        const params = {
          begin_date: this.daterange[0],
          end_date: this.daterange[1],
          chart_type: 0,
          top_type: "pv",
        };
        await this.requestData(params, (data) => {
          if (!isEmpty(data)) {
            this.data = data;
          } else {
            this.data = JSON.parse(JSON.stringify(defaultData));
          }
        });
      } catch (error) {
        this.$message.error(error.message);
        this.data = JSON.parse(JSON.stringify(defaultData));
      }
    },
  },
  beforeDestroy() {
    this.requestHandler.clearTimeout();
  },
  components: {
    Trend,
    TopTable,
  },
};
</script>

<style scoped>
.container {
  background: #ececec;
  height: 100%;
  width: 100%;
  padding: 10px;
  padding-bottom: 0;
  box-sizing: border-box;
  /* display: flex; */
  /* flex-direction: column; */
  text-align: left;
  min-width: 1120px;
  overflow: auto;
}
/* .header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  gap: 30px;
}
.header .title {
  font-size: 14px;
  font-weight: 600;
}
.header .icon {
  margin-right: 10px;
} */
.header {
  margin-bottom: 10px;
}
.main {
  width: 100%;
  /* flex: 1; */
  /* overflow: auto; */
}
.main .grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.main .grid .item {
  width: calc(50% - 5px);
  background: #fff;
  border: 1px solid #e1e2e2;
  border-radius: 2px;
  height: 413px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.main .grid .item:nth-child(2n) {
  margin-left: 10px;
}
</style>

<style>
#siteOverview .card-title {
  font-size: 14px;
  font-weight: 600;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#siteOverview .detail-report-btn {
  display: inline-block;
  text-align: center;
  background: #d5d6d8;
  color: #fff;
  font-weight: 800;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
#siteOverview .detail-report-btn:hover {
  background: var(--g-primary);
}
#siteOverview .card {
  height: 100%;
  width: 100%;
  background: #fff;
}
#siteOverview .el-table__header-wrapper th {
  font-weight: normal;
  color: #787a7d;
  font-size: 13px;
}
</style>