export const metricList = [
  // Page View 网站浏览量
  { label: "浏览量(PV)", value: "pv", type: "basicIndex" },
  // Unique Visitor 独立访客数
  { label: "访客数(UV)", value: "uv", type: "basicIndex" },
  // IP 独立IP数
  { label: "IP数", value: "ip", type: "basicIndex" },
  // Visit View 访客访问的次数
  { label: "访问次数(vv)", value: "vv", type: "basicIndex" },
  // BR 跳出率
  { label: "跳出率", value: "br", type: "qualityIndex" },
  // 人均浏览页数
  { label: "人均浏览页数", value: "ppu", type: "qualityIndex" },
  // 平均访问深度
  { label: "平均访问深度", value: "avd", type: "qualityIndex" },
];

export const recentTimeRanges = [
  { label: "昨天", value: "yesterday" },
  { label: "前天", value: "beforeYesterday" },
  { label: "最近7天", value: "last7day" },
  { label: "最近30天", value: "last30day" },
];

export const deviceTypes = [
  { label: "全部", value: 0 },
  { label: "手机", value: 1 },
  { label: "电脑", value: 2 },
];

export const top10Data = [
  {
    name: "来源网站",
    dataIndex: "source",
    isLink: true,
    chartType: 2,
    chartCategory: 22,
    detailPath: "/site-overview-source?chart_category=22",
  },
  {
    name: "入口页面",
    dataIndex: "path",
    listProperty: "page",
    // isLink: true,
    chartType: 3,
    chartCategory: 31,
    detailPath: "/site-overview-visit?chart_category=31",
  },
  {
    name: "地域分布",
    dataIndex: "country",
    listProperty: "visit",
    chartType: 4,
    chartCategory: 43,
    detailPath: "/site-overview-district?chart_category=43",
  },
];

export const refererTypeList = [
  { label: "全部", value: 0 },
  { label: "直接", value: 1 },
  { label: "内链", value: 2 },
  { label: "引擎", value: 3 },
  { label: "社媒", value: 4 },
  { label: "外链", value: 5 },
];

export const statusCodeList = [
  { label: "信息响应(100~199)", value: "1xx" },
  { label: "成功响应(200~299)", value: "2xx" },
  { label: "重定向响应(300~399)", value: "3xx" },
  { label: "客户端错误响应(400~499)", value: "4xx" },
  { label: "服务端错误响应(500~599)", value: "5xx" },
];

export const timeUnits = [
  { label: "按时", value: "hour" },
  { label: "按日", value: "day" },
  // { label: "按周", value: "week" },
  // { label: "按月", value: "month" },
];
