export const defaultData = {
  chart: {
    trend: [],
  },
  list: {
    source: [],
    page: [],
    visit: [],
  },
  result: {
    basic_index: {},
  },
};
