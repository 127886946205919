<template>
  <div class="container">
    <Detail :config="config" />
  </div>
</template>

<script>
import Detail from "../components/Detail.vue";
import { getDateRange } from "../util";

export default {
  data() {
    return {
      config: {
        title: "来源分析",
        defaultSearchParams: {
          chart_type: 2,
          device_type: 0,
          daterange: getDateRange("yesterday"),
          chart_category: this.$route.query.chart_category || "21",
          metric: "pv",
        },
        chartCategories: [
          {
            label: "来源类型",
            value: "21",
            listProperty: "source",
            dataIndex: "source_type",
          },

          {
            label: "来源网站",
            value: "22",
            listProperty: "source",
            dataIndex: "source",
            isLink: true,
          },
        ],
      },
    };
  },
  components: {
    Detail,
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  overflow: auto;
}
</style>