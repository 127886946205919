import { isEmpty } from "@/utils";

export default {
  data() {
    return {
      countries: [],
    };
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    async getCountries() {
      let key = "countriesList";
      let val = this.$common.getGlobalStorage(key);
      let countries = [];
      if (!isEmpty(val)) {
        countries = val || [];
      } else {
        try {
          const res = await this.$common.httpPost(
            "/api/address/admin/country/find/all"
          );
          if (res.code === "1000000") {
            countries = res.data || [];
            this.$common.setGlobalStorage(key, countries);
          } else {
            this.$message.error(res.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
      }
      this.countries = countries.map((item) => ({
        value: item.id,
        label: item.chinese_short_name,
      }));
    },
  },
};
