<template>
  <div class="container">
    <Detail :config="config" />
  </div>
</template>

<script>
import Detail from "../components/Detail.vue";
import { getDateRange } from "../util";

export default {
  data() {
    return {
      config: {
        title: "访问分析",
        defaultSearchParams: {
          chart_type: 3,
          device_type: 0,
          daterange: getDateRange("yesterday"),
          chart_category: this.$route.query.chart_category || "31",
          metric: "pv",
        },
        chartCategories: [
          {
            label: "入口页面",
            value: "31",
            listProperty: "page",
            dataIndex: "path",
          },
          {
            label: "状态码",
            value: "32",
            listProperty: "page",
            dataIndex: "status_code",
          },
          {
            label: "状态类型",
            value: "33",
            listProperty: "page",
            dataIndex: "status_grouped",
          },
        ],
      },
    };
  },
  components: {
    Detail,
  },
};
</script>

<style scoped>
.container {
  height: 100%;
  overflow: auto;
}
</style>