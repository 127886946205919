<template>
  <div class="site-summary">
    <div class="site-summary_item" v-for="item in metricList" :key="item.value">
      <div class="site-summary_item-title">{{ item.label }}</div>
      <div class="site-summary_item-value">
        <span class="site-summary_item-value-num">
          {{ formatValue(data[item.value], item.value) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { metricList } from "../data";
import { formatValue } from "../util";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formatValue,
      metricList,
    };
  },
  methods: {},
};
</script>

<style>
.site-summary {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 20px 0;
}
.site-summary_item {
  text-align: left;
  border-left: 1px solid #f0f0f0;
  padding: 0 20px;
  flex: 1;
}
.site-summary .site-summary_item:first-child {
  border-left: none;
}
.site-summary_item-title {
  color: #787a7d;
  font-size: 12px;
}
.site-summary_item-value {
  font-size: 20px;
  font-weight: 500;
  color: #121315;
  margin-top: 12px;
}
</style>