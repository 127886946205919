<template>
  <div class="header site-overview_search-form">
    <div class="title">
      {{ title }}
      <span style="padding-left: 8px">({{ daterangeFormat }})</span>
    </div>
    <div class="form">
      <div class="form-item">
        <span class="form-item_label">时间：</span>
        <el-radio-group v-model="recentTime" size="mini">
          <el-radio-button
            v-for="item in recentTimeRanges"
            :label="item.value"
            :key="item.value"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>
        <el-date-picker
          size="mini"
          style="margin-left: 10px"
          v-model="search.daterange"
          type="daterange"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          :clearable="false"
          :picker-options="pickerOptions"
          placement="bottom-start"
        ></el-date-picker>
      </div>

      <!-- <div class="form-item">
        <el-checkbox v-model="isCompare" size="mini">对比时间段</el-checkbox>
      </div> -->

      <div class="form-item" v-if="isTrend">
        <el-radio-group v-model="search.time_unit" size="mini">
          <el-radio-button
            v-for="item in timeUnits"
            :label="item.value"
            :key="item.value"
            :disabled="isDisabledTimeUnit(item.value)"
          >
            {{ item.label }}
          </el-radio-button>
        </el-radio-group>
      </div>

      <div class="form-item">
        <span class="form-item_label">设备：</span>
        <div class="form-item_content">
          <el-radio-group v-model="search.device_type" size="mini">
            <el-radio-button
              v-for="item in deviceTypes"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </div>
      <div class="form-item">
        <span class="form-item_label">来源类型：</span>
        <div class="form-item_content">
          <el-select
            style="width: 90px"
            size="mini"
            v-model="search.referer_type"
            clearable
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in refererTypeList"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <span class="form-item_label">状态码：</span>
        <div class="form-item_content">
          <el-select
            size="mini"
            v-model="search.status_code"
            clearable
            placeholder="请选择"
            filterable
            allow-create
            default-first-option
            multiple
          >
            <el-option
              v-for="item in statusCodeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <span class="form-item_label">国家：</span>
        <div class="form-item_content">
          <el-select
            size="mini"
            v-model="search.country"
            clearable
            placeholder="请选择"
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="item in countries"
              :label="item.label"
              :value="item.label"
              :key="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="form-item">
        <span class="form-item_label">路径：</span>
        <div class="form-item_content">
          <el-input
            v-model="search.path"
            placeholder="请输入"
            size="mini"
            clearable
          >
          </el-input>
        </div>
      </div>
      <div class="form-item">
        <span class="form-item_label">IP Address：</span>
        <div class="form-item_content">
          <el-input
            size="mini"
            v-model="search.remote_addr"
            placeholder="请输入"
            clearable
          >
          </el-input>
        </div>
      </div>
      <div>
        <el-button
          type="primary"
          size="mini"
          class="search_icon"
          @click="handleSearch"
        >
          <i class="el-icon-search"></i>
          搜索
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  deviceTypes,
  recentTimeRanges,
  refererTypeList,
  statusCodeList,
  timeUnits,
} from "../data";
import countriesMixin from "@/mixins/countries";
import { getDateRange } from "../util";
import { formatDate } from "@/utils";
import isEqual from "lodash/isEqual";

export default {
  mixins: [countriesMixin],
  props: {
    title: {
      type: String,
      default: "",
    },
    isTrend: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Object,
      default: () => {
        return {
          chart_type: undefined,
          daterange: getDateRange("yesterday"),
          device_type: 0,
          chart_category: undefined,
          metric: "pv",
          country: undefined,
          path: undefined,
          remote_addr: undefined,
          referer_type: 0,
          status_code: undefined,
          time_unit: undefined,
        };
      },
    },
    daterange: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCompare: false,
      recentTime: undefined,
      timeUnits,
      recentTimeRanges,
      refererTypeList,
      deviceTypes,
      statusCodeList,

      pickDate: "", // 存放getPickDate获取的数据
      pickerOptions: {
        shortcuts: [
          {
            text: "本月",
            onClick(picker) {
              const end = new Date();
              const start = new Date(end.getFullYear(), end.getMonth(), 1);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        onPick: this.getPickDate,
        disabledDate: this.disabledDate,
      },
    };
  },
  computed: {
    daterangeFormat() {
      const [start, end] = this.daterange || [];

      return `${formatDate(start, "YYYY/MM/DD")}~${formatDate(
        end,
        "YYYY/MM/DD"
      )}`;
    },
  },
  watch: {
    "search.daterange": {
      handler(v) {
        const yesterday = getDateRange("yesterday");
        const beforeYesterday = getDateRange("beforeYesterday");
        const last7day = getDateRange("last7day");
        const last30day = getDateRange("last30day");
        if (isEqual(v, yesterday)) {
          this.recentTime = "yesterday";
        } else if (isEqual(v, beforeYesterday)) {
          this.recentTime = "beforeYesterday";
        } else if (isEqual(v, last7day)) {
          this.recentTime = "last7day";
        } else if (isEqual(v, last30day)) {
          this.recentTime = "last30day";
        } else {
          this.recentTime = null;
        }

        const [begin, end] = v;
        const diff =
          Math.abs(new Date(end) - new Date(begin)) / (1000 * 60 * 60 * 24);
        // 如果差异小于等于1天并且timeUnit不为hour，则改为hour
        // 如果差异小于等于7天并且timeUnit不为hour或day, 则改为hour
        // 如果差异小于等于32天并且timeUnit不为hour、day、week, 则改为hour

        const shouldSetToHour = (days, units) =>
          diff <= days && !units.includes(this.search.time_unit);

        if (shouldSetToHour(1, ["hour"])) {
          this.search.time_unit = "hour";
        } else if (shouldSetToHour(7, ["hour", "day"])) {
          this.search.time_unit = "hour";
        } else if (shouldSetToHour(32, ["hour", "day", "week"])) {
          this.search.time_unit = "hour";
        }
      },
      deep: true,
      immediate: true,
    },
    recentTime: {
      handler(v) {
        if (v != null) {
          this.search.daterange = getDateRange(v);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getPickDate(pick) {
      this.pickDate = pick;
    },
    disabledDate(date) {
      const { minDate, maxDate } = this.pickDate;
      if (minDate && !maxDate) {
        const diff = Math.abs(minDate.valueOf() - date.valueOf());
        if (diff > 1000 * 3600 * 24 * 30) {
          return true;
        }
      }
    },
    isDisabledTimeUnit(timeUnit) {
      // 如果跨度小于等于1天，则只启动时
      // 如果跨度小于等于7天，则启用时、日
      // 如果跨度小于等于32天，则启用时、日、周
      const [begin, end] = this.search.daterange;
      const diff =
        Math.abs(new Date(end) - new Date(begin)) / (1000 * 60 * 60 * 24);

      if (diff <= 1) {
        return timeUnit !== "hour";
      } else if (diff <= 7) {
        return timeUnit !== "hour" && timeUnit !== "day";
      } else if (diff <= 32) {
        return timeUnit !== "hour" && timeUnit !== "day" && timeUnit !== "week";
      } else {
        return false;
      }
    },
    handleSearch() {
      this.$emit("search", this.search);
    },
  },
};
</script>

<style scoped>
.header {
  text-align: left;
  background: #fff;
  position: sticky;
  top: 0;
  padding: 15px 20px;
  z-index: 100;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.header .title {
  font-size: 14px;
  padding-bottom: 10px;
}
.form {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.form-item {
  display: flex;
  align-items: center;
}
.form-item_label {
  font-size: 12px;
  display: inline-block;
  color: #787a7d;
}
</style>

<style>
.site-overview_search-form .el-checkbox__label {
  font-size: 12px;
  padding-left: 4px;
  line-height: 1;
}
.el-checkbox__input {
  height: 14px;
}
</style>