var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend-container"},_vm._l((_vm.columns),function(item,index){return _c('div',{key:item.value || item,on:{"click":function($event){return _vm.handleLegendChange(item.value || item)}}},[_c('div',{staticClass:"legend",style:({
        color: _vm.excludedValues.includes(item.value || item)
          ? '#999'
          : 'rgb(50, 52, 55)',
        cursor: _vm.readOnly ? 'default' : 'pointer',
      }),attrs:{"slot":"reference"},slot:"reference"},[_c('span',{staticClass:"legend_dot",style:({
          backgroundColor: _vm.excludedValues.includes(item.value || item)
            ? '#999'
            : _vm.colors[index],
        })}),_vm._v(" "+_vm._s(item.label || item)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }