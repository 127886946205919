<template>
  <div>
    <ul class="radio-group">
      <li
        v-for="item in data.slice(0, 2)"
        :key="item.value"
        :class="['radio-group-item', { active: value === item.value }]"
      >
        <span class="radio-group-item__inner" @click="emitValue(item.value)">
          {{ item.label }}
        </span>
      </li>
      <li
        class="radio-group-item dropdown"
        :class="{ active: isDropdownActive }"
      >
        <span
          class="radio-group-item__inner"
          @click.stop="showDropdown = !showDropdown"
        >
          {{ getLastLabel() }} <i class="el-icon-arrow-down"></i>
        </span>
        <div
          ref="dropdownContent"
          class="dropdown-content-wrapper"
          :class="{ active: showDropdown }"
        >
          <ul class="dropdown-content">
            <li
              v-for="item in data.slice(2)"
              :key="item.value"
              :class="{ active: item.value === value }"
              @click="emitValue(item.value)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    isDropdownActive() {
      return this.data.slice(2).some((item) => item.value === this.value);
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  methods: {
    getLastLabel() {
      const current = this.data
        .slice(2)
        .find((item) => item.value === this.value);
      return current ? current.label : "其它";
    },
    emitValue(val) {
      this.$emit("input", val);
      this.showDropdown = false; // Close dropdown after selection
    },
    handleClickOutside(event) {
      const divToHide = this.$refs.dropdownContent;

      if (divToHide && !divToHide.contains(event.target)) {
        if (this.showDropdown) {
          this.showDropdown = false;
        }
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.radio-group {
  font-size: 12px;
  line-height: 1;
  color: #606266;
}

.radio-group-item {
  cursor: pointer;
  float: left;
}

.radio-group-item__inner {
  border: 1px solid #dcdfe6;
  display: inline-block;
  padding: 9px 15px;
  background: #fff;
  text-align: center;
  transition: all 0.3s;
  border-left: none;
}

.radio-group .radio-group-item:first-child .radio-group-item__inner {
  border-left: 1px solid #dcdfe6;
  border-radius: 4px 0 0 4px;
}

.radio-group .radio-group-item:last-child .radio-group-item__inner {
  border-radius: 0 4px 4px 0;
}

.radio-group-item.active .radio-group-item__inner {
  color: #fff;
  background-color: var(--g-primary);
  border-color: var(--g-primary);
}

.dropdown {
  position: relative;
}

.dropdown-content-wrapper {
  position: absolute;
  background-color: #fff;
  width: 158px;
  max-height: 0;
  /* border: 1px solid #e4e7ed; */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 1;
  top: 34px;
  transform-origin: center top;
  transition: max-height 0.1s ease;
  overflow: hidden;
  box-sizing: border-box;
  will-change: max-height;
}

.dropdown-content-wrapper.active {
  max-height: 300px;
}

.dropdown-content {
  /* display: none; */
  height: 100%;
  overflow: auto;
}

.dropdown-content li {
  padding: 10px;
  padding: 12px 16px;
  transition: all 0.3s;
}

.dropdown-content li.active {
  color: var(--g-primary);
}

.dropdown-content li:hover {
  background: #f3f4f4;
}
</style>
