class BaseLoading {
  constructor() {
    this.loading = false;
    this.reqNum = 0;
    this.timerId = null;
  }

  startLoading() {
    this.reqNum++;
    this.updateLoadingState();
  }

  endLoading() {
    this.timerId = setTimeout(() => this.closeLoading(), 100);
  }

  closeLoading() {
    this.reqNum = Math.max(this.reqNum - 1, 0);
    this.updateLoadingState();
  }

  updateLoadingState() {
    this.loading = this.reqNum > 0;
  }

  clearTimeout() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  }
}

export default BaseLoading;
