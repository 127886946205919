<template>
  <div class="card site-overview-trend">
    <div class="trend-container">
      <div class="card-title">
        <span>趋势图</span>
        <span
          class="detail-report-btn"
          @click="$router.push('/site-overview-trend')"
        >
          <i class="el-icon-arrow-right"></i>
        </span>
      </div>
      <div class="header">
        <div>
          <RadioGroup v-model="activeMetric" :data="metricList" />
        </div>
        <Compare v-if="isOneDay && !isEmpty(trendData)" v-model="compareType" />
      </div>
      <div class="chart">
        <trend-chart
          v-loading="requestHandler.loading || loading"
          element-loading-spinner="el-icon-loading"
          element-loading-text="加载中"
          :data="trendData"
          :metric="activeMetric"
          :daterange="daterange"
          :compareDateRange="compareDateRange"
          :compareData="compareData"
          :timeUnit="isOneDay ? 'hour' : 'day'"
          height="280px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "@/utils";
import { metricList } from "../../data";
import RadioGroup from "../../components/RadioGroup.vue";
import RequestHandler from "@/utils/request";
import TrendChart from "../../components/TrendChart.vue";
import Compare from "../../components/Compare.vue";
import { getCompareDateRange } from "../../util";

export default {
  props: {
    daterange: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEmpty,
      activeMetric: "pv",
      metricList: metricList,
      trendData: [],
      compareData: [],
      compareType: "lastday",
      compareDateRange: [],
      compareTypeOptions: [
        {
          label: "前一天",
          value: 1,
        },
        {
          label: "上周同期",
          value: 2,
        },
      ],
      requestHandler: new RequestHandler(),
    };
  },
  computed: {
    isOneDay() {
      const [start, end] = this.daterange;
      return start === end;
    },
    updateCompareFlag() {
      return {
        compareType: this.compareType,
        daterange: this.daterange,
      };
    },
  },
  watch: {
    data: {
      handler() {
        this.trendData = this.data.chart.trend || [];
      },
      deep: true,
    },
    daterange() {
      this.compareType = this.isOneDay ? "lastday" : null;

      this.trendData = [];
      this.compareData = [];
    },
    updateCompareFlag: {
      handler(newVal) {
        if (newVal.compareType) {
          const compareDateRange = getCompareDateRange(
            this.compareType,
            this.daterange[0]
          );
          this.compareDateRange = compareDateRange;

          this.getCompareData();
        } else {
          this.compareData = [];
          this.compareDateRange = null;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    async fetchData(params) {
      return await this.$common.httpPost(
        "/api/log/admin/nginx/find/visualization",
        params
      );
    },
    async getCompareData() {
      try {
        if (this.requestCompareData == null) {
          this.requestCompareData = this.requestHandler.create(this.fetchData);
        }
        const params = {
          begin_date: this.compareDateRange[0],
          end_date: this.compareDateRange[1],
          chart_type: 1,
        };
        await this.requestCompareData(params, (data) => {
          this.compareData = data.chart.trend || [];
        });
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
  beforeDestroy() {
    this.requestHandler.clearTimeout();
  },
  components: {
    RadioGroup,
    TrendChart,
    Compare,
  },
};
</script>

<style scoped>
.trend-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10;
}
.trend-container .header {
  display: flex;
  justify-content: space-between;
  padding-inline: 20px;
}

.trend-container .header .compare {
  display: flex;
  font-size: 13px;
  align-items: center;
}

.trend-container .chart {
  height: 100%;
  flex: 1;
  overflow: hidden;
  padding-top: 10px;
  padding-inline: 10px;
}
</style>