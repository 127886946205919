<template>
  <div class="site-detail-table">
    <el-table
      ref="table"
      :data="tableData"
      style="width: 100%"
      max-height="600px"
      :summary-method="getSummaries"
      show-summary
      v-loading="loading"
      @sort-change="sortChange"
      element-loading-spinner="el-icon-loading"
      element-loading-text="加载中"
    >
      <el-table-column :label="name" :prop="dataIndex">
        <template slot-scope="scope">
          <div>
            <a
              v-if="isLink"
              target="_blank"
              class="ellipsis"
              :href="scope.row[dataIndex]"
            >
              {{ scope.row[dataIndex] }}
            </a>
            <span v-else class="ellipsis">{{ scope.row[dataIndex] }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="type in ['basicIndex', 'qualityIndex']"
        :key="type"
        :label="type === 'basicIndex' ? '网站基础指标' : '流量质量指标'"
      >
        <el-table-column
          v-for="item in metricList.filter((item) => item.type === type)"
          :key="item.value"
          :label="item.label"
          :prop="item.value"
          width="120"
          :sortable="isTrend ? true : 'custom'"
          :sort-orders="isTrend ? undefined : ['ascending', 'descending']"
        >
          <template slot-scope="scope">
            {{ formatValue(scope.row[item.value], item.value) }}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="pagination"
      style="padding: 20px"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.page_number"
      :page-sizes="pageSizes"
      :page-size="page.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>

<script>
import { metricList } from "../data";
import { basicIndex, calcAVD, calcBR, calcPPU, formatValue } from "../util";

export default {
  props: {
    isTrend: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    name: {
      require: true,
      type: String,
    },
    dataIndex: {
      require: true,
      type: String,
    },
    search: {
      type: Object,
      default: () => {},
    },
    chartType: {
      require: true,
      type: Number,
    },
    isLink: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    pageSizes: {
      type: Array,
      default: () => [20, 50, 100, 150],
    },
    page: {
      type: Object,
      default: () => ({
        page_number: 1,
        page_size: 20,
      }),
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    metric: {
      type: String,
    },
  },
  data() {
    return {
      metricList,
      formatValue,
    };
  },
  methods: {
    sort(prop, order) {
      this.$refs.table.sort(prop, order);
    },
    clearSort() {
      this.$refs.table.clearSort();
    },
    sortChange({ prop, order }) {
      if (this.isTrend) return;
      this.$emit("sort", {
        prop,
        order: order === "ascending" ? "-" : "",
      });
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      // 校验数据是否为空
      if (!Array.isArray(data) || data.length === 0) {
        return sums.fill("--", 0, columns.length);
      }

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "当前汇总";
          return;
        }

        if (basicIndex.includes(column.property)) {
          const values = data
            .map((item) => Number(item[column.property]))
            .filter(Number.isFinite);
          sums[index] =
            values.length > 0
              ? formatValue(
                  values.reduce((a, b) => a + b, 0),
                  column.property
                )
              : "--";
        } else {
          const value = this.reduceData(data);

          sums[index] = formatValue(
            this.calcMetricValue(value, column.property),
            column.property
          );
        }
      });

      return sums;
    },

    reduceData(data) {
      // 校验数据是否为空
      if (!Array.isArray(data) || data.length === 0) {
        return { spvv: undefined, vv: undefined, pv: undefined, uv: undefined };
      }

      return data.reduce(
        (acc, cur) => {
          // 遍历 basicIndex 并累加有效数字
          basicIndex.forEach((property) => {
            // 校验每个属性是否存在且为有效数字
            if (Number.isFinite(cur[property])) {
              acc[property] = (acc[property] || 0) + cur[property];
            }
          });
          return acc;
        },
        { spvv: 0, vv: 0, pv: 0, uv: 0 }
      );
    },

    calcMetricValue(value, metric) {
      const metricFunctions = {
        br: calcBR,
        ppu: calcPPU,
        avd: calcAVD,
      };

      return metricFunctions[metric] ? metricFunctions[metric](value) : null;
    },
    handleSizeChange(val = 20) {
      this.page.page_size = val;
      this.$emit("change");
    },
    handleCurrentChange(val = 1) {
      const maxPageNum = Math.ceil(this.totalCount / this.page.page_size);
      this.page.page_number = maxPageNum >= val ? val : maxPageNum;
      this.$emit("change");
    },
  },
};
</script>

<style>
.site-detail-table {
  padding: 20px;
}
.site-detail-table .el-table .cell {
  font-size: 12px;
  color: #323437;
}

.site-detail-table .el-table thead .cell {
  color: #5d5d61;
  font-weight: 500;
}

a {
  text-decoration: none;
  color: #1276e5;
  cursor: pointer;
}

a:hover {
  color: #282c33;
}
</style>