<template>
  <div
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="加载中"
  >
    <el-empty v-show="isEmpty(data)" description="暂无数据"></el-empty>
    <div v-show="!isEmpty(data)">
      <div ref="container" :style="{ height: height, width: '100%' }"></div>
      <ChartLegend
        :excludedValues="excludedValues"
        :colors="colors"
        :columns="columns"
        @legend-change="handleLegendChange"
        :readOnly="columns.length <= 1"
      />
    </div>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import {
  fillMissingDates,
  fillMissingHours,
  formatValue,
  getAxisYlabelFormatter,
} from "../util";
import { metricList } from "../data";
import { formatDate, isEmpty } from "@/utils";
import ChartLegend from "./Legend.vue";
import _ from "lodash";

export default {
  props: {
    daterange: {
      require: true,
      type: Array,
    },
    data: {
      type: Array,
      default: () => [],
    },
    metric: {
      type: String,
      default: "",
    },
    compareDateRange: {
      type: Array,
    },
    compareData: {
      type: Array,
      default: () => [],
    },
    timeUnit: {
      type: String,
      default: "hour",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isFolded: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "320px",
    },
  },
  data() {
    return {
      isEmpty,
      chart: null,
      colors: ["#1890FF", "#badcfc"],
      excludedValues: [],
      debouncedRebuildChart: null,
    };
  },
  computed: {
    columns() {
      if (this.showCompare) {
        const [start1, end1] = this.daterange;
        const [start2, end2] = this.compareDateRange;

        return [
          start1 === end1 ? `${start1}` : `${start1} - ${end1}`,
          start2 === end2 ? `${start2}` : `${start2} - ${end2}`,
        ].map((item) => ({
          value: item,
          label: `${formatDate(item, "YYYY/MM/DD")} ${this.metricLabel}`,
        }));
      }
      return [this.metricLabel];
    },
    prepareMainData() {
      if (this.data.length === 0) return [];
      return this.isHour
        ? fillMissingHours(this.data)
        : fillMissingDates(this.data, this.daterange);
    },
    prepareCompareData() {
      if (this.compareData.length === 0) return [];

      return this.isHour
        ? fillMissingHours(this.compareData)
        : fillMissingDates(this.compareData, this.compareDateRange);
    },
    mergeMainAndCompareData() {
      return this.prepareMainData.map((item) => {
        const compare = this.prepareCompareData.find(
          (i) => i.hour === item.hour
        );

        return {
          ...item,
          date: this.daterange[0],
          compare_date: this.compareDateRange[0],
          compare,
        };
      });
    },
    displayData() {
      return this.showCompare
        ? this.mergeMainAndCompareData
        : this.prepareMainData;
    },
    showCompare() {
      return !isEmpty(this.compareData) && this.isOneDay;
    },
    isOneDay() {
      return this.daterange[0] === this.daterange[1];
    },
    isHour() {
      return this.timeUnit === "hour" || this.isOneDay;
    },
    metricLabel() {
      return metricList.find((item) => item.value === this.metric).label;
    },
    flag() {
      return {
        metric: this.metric,
        data: this.displayData,
        showCompare: this.showCompare,
        isFolded: this.isFolded,
      };
    },
  },
  watch: {
    flag: {
      handler: function () {
        if (this.chart) {
          this.chart.destroy();
          this.chart = null;
        }
        this.excludedValues = [];
        this.$nextTick(() => this.debouncedRebuildChart());
      },
      deep: true,
    },
  },
  created() {
    this.debouncedRebuildChart = _.debounce(() => {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      this.buildChart();
    }, 100);
  },
  methods: {
    buildChart() {
      const container = this.$refs.container;
      const showCompare = this.showCompare;
      const data = this.displayData;
      const isHour = this.isHour;

      const chart = new Chart({
        container: container,
        autoFit: true,
      });

      chart
        .data(data)
        .scale("y", {
          domainMin: 0,
          nice: true,
        })
        .axis("y", {
          labelFormatter: getAxisYlabelFormatter(this.metric),
          title: false,
        })
        .axis("x", {
          title: false,
          labelAutoHide: {
            type: "hide",
            keepHeader: false,
            keepTail: false,
          },
          labelAutoRotate: false,
          overflowHide: true,
          labelFormatter: (d) => {
            if (!isHour) return formatDate(d, "YYYY/MM/DD");
            const trimmed = d.replace(/^0+/, "");
            return trimmed === "" ? "0" : trimmed;
          },
        });
      if (!this.excludedValues.includes(this.daterange[0])) {
        // 面积图
        chart
          .area()
          .style("opacity", 0.2)
          .encode("x", (d) => (isHour ? d.hour : new Date(d.date)))
          .encode("y", (d) => (d[this.metric] == null ? 0 : d[this.metric]))
          .encode("color", (d) => (isHour ? d.date : undefined))
          .tooltip({
            title: (d) => {
              if (!isHour) {
                return formatDate(d.date, "YYYY/MM/DD");
              } else {
                return `${d.hour}:00 - ${d.hour}:59 ${this.metricLabel}`;
              }
            },
            items: [
              (
                d // 每一个数据项
              ) => {
                return {
                  title: false,
                  name: isHour ? this.daterange[0] : this.metricLabel,
                  value:
                    d[this.metric] != null
                      ? formatValue(d[this.metric], this.metric)
                      : "--",
                };
              },
            ],
          });

        // 面积图的点
        chart
          .point()
          .encode("x", (d) => (isHour ? d.hour : new Date(d.date)))
          .encode("y", (d) => (d[this.metric] == null ? 0 : d[this.metric]))
          .encode("color", (d) => (isHour ? d.date : undefined))
          .style("shape", "point")
          .tooltip(false);

        // 面积图的线
        chart
          .line()
          .encode("x", (d) => (isHour ? d.hour : new Date(d.date)))
          .encode("y", (d) => (d[this.metric] == null ? 0 : d[this.metric]))
          .encode("color", (d) => (isHour ? d.date : undefined))
          .style("lineWidth", 2)
          .tooltip(false);
      }

      if (
        showCompare &&
        !this.excludedValues.includes(this.compareDateRange[0])
      ) {
        // 比较线
        chart
          .line()
          .encode("color", (d) => d.compare_date)
          .encode("x", (d) => (isHour ? d.hour : new Date(d.compare_date)))
          .encode("y", (d) =>
            d.compare[this.metric] == null ? 0 : d.compare[this.metric]
          )
          .style("lineWidth", 2)
          .tooltip({
            title: false,
            items: [
              (
                d // 每一个数据项
              ) => {
                return {
                  title: false,
                  value:
                    d.compare[this.metric] != null
                      ? formatValue(d.compare[this.metric], this.metric)
                      : "--",
                };
              },
            ],
          });

        // 比较线的点
        chart
          .point()
          .encode("color", (d) => d.compare_date)
          .encode("x", (d) => (isHour ? d.hour : new Date(d.compare_date)))
          .encode("y", (d) =>
            d.compare[this.metric] == null ? 0 : d.compare[this.metric]
          )
          .style("shape", "point")
          .tooltip(false);
      }

      if (showCompare) {
        chart.scale("color", {
          range: this.colors,
          domain: [this.daterange[0], this.compareDateRange[0]],
        });
      } else {
        chart.scale("color", { range: this.colors[0] });
      }

      chart.legend(false);

      chart.render();
      this.chart = chart;
    },
    handleLegendChange({ excludedValues }) {
      this.excludedValues = excludedValues;
      this.chart.destroy();
      this.chart = null;
      this.$nextTick(() => this.buildChart());
    },
  },
  components: {
    ChartLegend,
  },
};
</script>

<style>
</style>