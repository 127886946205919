import BaseLoading from "./loading.js";

class RequestHandler extends BaseLoading {
  constructor() {
    super();
  }

  create(asyncFunction) {
    let currentRequestId = null;
    return async (params, callback) => {
      const requestId = Date.now();
      currentRequestId = requestId;

      try {
        this.startLoading();
        const res = await asyncFunction(params);
        this.endLoading();

        if (res.code === "1000000") {
          if (requestId === currentRequestId) {
            callback(res.data);
          }
        } else {
          throw new Error("Request failed with code: " + res.code);
        }
      } catch (error) {
        this.endLoading();
        console.error(error);
        throw error;
      }
    };
  }
}

export default RequestHandler;
