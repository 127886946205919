<template>
  <div
    class="chart-wrapper"
    style="width: 100%; height: 100%"
    v-loading="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="加载中"
  >
    <IndexSummary :data="summary" />

    <div v-show="!isFoldedChart">
      <el-empty
        v-show="isEmpty(pieData) && isEmpty(trendData)"
        description="暂无数据"
      ></el-empty>
      <div v-show="!isEmpty(pieData) && !isEmpty(trendData)">
        <div class="line-pie-chart">
          <div ref="pie" style="width: 30%; height: 100%"></div>
          <div ref="line" style="width: 70%; height: 100%"></div>
        </div>
        <div style="padding: 10px 20px">
          <ChartLegend
            :columns="columns"
            :excludedValues="excludedValues"
            :colors="colors"
            @legend-change="handleLegendChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { metricList } from "../data";
import IndexSummary from "./Summary.vue";
import { Chart } from "@antv/g2";
import {
  fillMissingDates,
  fillMissingHours,
  formatValue,
  getAxisYlabelFormatter,
} from "../util";
import { isEmpty, formatDate } from "@/utils";
import ChartLegend from "./Legend.vue";
import _ from "lodash";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dataIndex: {
      require: true,
      type: String,
    },
    metric: {
      require: true,
      type: String,
    },
    daterange: {
      type: Array,
      default: () => [],
    },
    listProperty: {
      type: String,
    },
    trendData: {
      type: Array,
      default: () => [],
    },
    pieData: {
      type: Array,
      default: () => [],
    },
    summary: {
      type: Object,
      default: () => ({}),
    },
    isFoldedChart: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEmpty,
      metricList,
      colors: [],
      excludedValues: [],
      debouncedRebuildChart: null,
    };
  },
  computed: {
    isHour() {
      const [start, end] = this.daterange || [];
      return start === end;
    },
    formatTrendData() {
      if (isEmpty(this.trendData)) return [];
      if (this.isHour) {
        return fillMissingHours(this.trendData, this.dataIndex);
      } else {
        return fillMissingDates(this.trendData, this.daterange, this.dataIndex);
      }
    },
    metricLabel() {
      return metricList.find((item) => item.value === this.metric).label;
    },
    columns() {
      const result = this.pieData.reduce((acc, cur) => {
        if (!acc.includes(cur[this.dataIndex]) && cur[this.dataIndex] != null) {
          acc.push(cur[this.dataIndex]);
        }
        return acc;
      }, []);
      return result;
    },
    dataFlag() {
      return {
        trend: this.formatTrendData,
        pie: this.pieData,
        isHour: this.isHour,
        metric: this.metric,
        isFoldedChart: this.isFoldedChart,
      };
    },
  },
  watch: {
    dataFlag: {
      handler() {
        if (this.pieChart) {
          this.pieChart.destroy();
          this.pieChart = null;
        }
        if (this.lineChart) {
          this.lineChart.destroy();
          this.lineChart = null;
        }
        this.$nextTick(() => {
          this.debouncedRebuildChart();
        });
      },
      deep: true,
    },
  },
  created() {
    this.debouncedRebuildChart = _.debounce(() => {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      this.buildChart();
    }, 100);
  },
  mounted() {
    this.debouncedRebuildChart();
  },
  methods: {
    buildChart() {
      this.excludedValues = this.columns.slice(5);

      const pieContainer = this.$refs.pie;
      const lineContainer = this.$refs.line;

      const pieChart = new Chart({
        container: pieContainer,
        autoFit: true,
      });

      pieChart
        .data(
          this.pieData.filter(
            (item) => !this.excludedValues.includes(item[this.dataIndex])
          )
        )
        .encode("y", this.metric)
        .encode("color", this.dataIndex)
        .transform({ type: "stackY" });

      pieChart
        .interval()
        .coordinate({ type: "theta", outerRadius: 0.7, innerRadius: 0.5 })
        .tooltip({
          title: () => this.metricLabel,
          items: [
            (d, i, data, column) => {
              return {
                name: d[this.dataIndex],
                value: formatValue(column.y0.value[i], this.metric),
              };
            },
            (d, _i, data) => {
              const metricValue = d[this.metric];
              const metricTotalValue = data.reduce(
                (acc, cur) => acc + cur[this.metric],
                0
              );
              // 数据类型检查
              if (
                typeof metricValue !== "number" ||
                typeof metricTotalValue !== "number"
              ) {
                return "--";
              }

              // 除零检查
              if (metricTotalValue === 0) {
                return "--";
              }
              const percentage = (
                (d[this.metric] / metricTotalValue) *
                100
              ).toFixed(2);

              return {
                color: false,
                name: "占比",
                value: `${percentage}%`,
              };
            },
          ],
        })
        .animate("enter", { type: "waveIn" })
        .animate("update", { type: "waveIn" })
        .legend(false);

      pieChart.interaction("tooltip", {
        // mount: document.body,
        bounding: pieContainer,
      });

      pieChart.interaction("elementHighlight");

      pieChart.scale("color", {
        domain: this.columns,
      });

      const lineChart = new Chart({
        container: lineContainer,
        autoFit: true,
      });
      lineChart
        .data(
          this.formatTrendData.filter(
            (item) => !this.excludedValues.includes(item[this.dataIndex])
          )
        )
        .encode("x", (d) => (this.isHour ? d.hour : new Date(d.date)))
        .encode("y", (d) => {
          return d[this.metric] == null ? 0 : d[this.metric];
        })
        .encode("color", this.dataIndex)
        .axis("x", {
          title: false,
          labelAutoHide: {
            type: "hide",
            keepHeader: false,
            keepTail: false,
          },
          labelAutoRotate: false,
          labelFormatter: (d) => {
            if (!this.isHour) return formatDate(d, "YYYY/MM/DD");
            const trimmed = d.replace(/^0+/, "");
            return trimmed === "" ? "0" : trimmed;
          },
        })
        .axis("y", {
          title: false,
          labelFormatter: getAxisYlabelFormatter(this.metric),
        });

      lineChart
        .line()
        .legend(false)
        .style("lineWidth", 2)
        .tooltip({
          title: (d) =>
            this.isHour
              ? `${d.hour}:00~${d.hour}:59 ${this.metricLabel}`
              : `${d.date} ${this.metricLabel}`,
          items: [
            (d) => {
              const keyword = this.isHour ? "hour" : "date";

              const value =
                this.formatTrendData.find(
                  (item) =>
                    item[keyword] === d[keyword] &&
                    item[this.dataIndex] === d[this.dataIndex]
                ) || {};
              return {
                name: d[this.dataIndex],
                value:
                  value[this.metric] != null
                    ? formatValue(value[this.metric], this.metric)
                    : "--",
              };
            },
          ],
        });

      lineChart.point().encode("shape", "point").tooltip(false);

      lineChart.scale("color", {
        domain: this.columns,
      });

      lineChart.interaction("tooltip", {
        // mount: document.body,
      });

      // lineChart.interaction("elementHighlightByColor");

      // 交互事件
      // pieChart.on("element:highlight", (event) => {});

      pieChart.render();
      lineChart.render();

      pieChart.on("afterrender", () => this.initLegendData(pieChart));

      this.pieChart = pieChart;
      this.lineChart = lineChart;
    },
    initLegendData(chart) {
      const scale = chart.getScaleByChannel("color");
      const { range } = scale.getOptions();
      this.colors = range;
    },
    handleLegendChange({ excludedValues }) {
      this.excludedValues = excludedValues;

      // 过滤数据
      const filteredData = this.pieData.filter(
        (item) => !excludedValues.includes(item[this.dataIndex])
      );

      const filteredTrendData = this.formatTrendData.filter(
        (item) => !excludedValues.includes(item[this.dataIndex])
      );

      // 更新图表数据
      this.pieChart.changeData(filteredData);
      this.lineChart.changeData(filteredTrendData);
    },
  },
  components: {
    IndexSummary,
    ChartLegend,
  },
};
</script>

<style scoped>
.line-pie-chart {
  display: flex;
  width: 100%;
  height: 320px;
  border-top: 1px solid #f0f0f0;
  padding-top: 20px;
}
</style>